import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)
dayjs.tz.setDefault('America/Denver')

import { useSessionStore } from '@stores'

import ExclamationMarkIcon from '@/svg/caution-exclamation-mark.svg?raw'
import CloseIcon from '@/svg/x.svg?raw'

export const defaultSwalOptions = {
	showCloseButton: true,
	allowOutsideClick: true,
	allowEscapeKey: true,
	closeButtonHtml: CloseIcon,
	icon: null,
	backdrop: true,
	iconHtml: ExclamationMarkIcon,
	buttonsStyling: false,
	customClass: {
		confirmButton: 'btn btn-primary',
		cancelButton: 'btn btn-outline-primary',
		popup: 'swal2-popup-overrides',
		title: 'swal2-title-overrides',
	},
}
export const getSwalOptions = () => {
	return {
		...defaultSwalOptions,
	}
}
export const addressVerificationSwalOptions = (userAddress, verifiedAddress) => {
	const hasError = verifiedAddress.error
	return {
		...defaultSwalOptions,
		showCloseButton: false,
		allowOutsideClick: false,
		allowEscapeKey: false,
		title: 'Verify Your Address',
		html: `<div>
      <p>We were unable to verify the address you entered${!hasError ? ' but found a close match' : ''}.</p>
      <p style="text-align:left"><span style="font-weight:700;display:inline-block;margin-bottom:4px">You Entered</span><br />
      ${userAddress.address1}${userAddress.address2 ? `, ${userAddress.address2}` : ''}<br />
      ${userAddress.city} ${userAddress.state}, ${userAddress.zip}
      </p>
      ${
				!hasError
					? `<p style="text-align:left"><span style="font-weight:700;display:inline-block;margin-bottom:4px">Recommended</span><br />
          ${verifiedAddress.street}${userAddress.address2 ? `, ${userAddress.address2}` : ''}<br />
          ${verifiedAddress.city} ${verifiedAddress.state}, ${verifiedAddress.zip}
          </p>`
					: ''
			}
      </div>`,
		showConfirmButton: true,
		confirmButtonText: `${!hasError ? 'Use Recommended Address' : 'Enter A Different Address'}`,
		showCancelButton: true,
		cancelButtonText: `${!hasError ? 'Use Your Address' : 'Use This Address Anyway'}`,
		customClass: {
			confirmButton: 'btn btn-primary',
			cancelButton: 'underline-link',
			popup: 'swal2-popup-overrides',
			title: 'swal2-title-overrides',
		},
	}
}

export const shippingModal = (countryCode) => {
	return {
		...defaultSwalOptions,
		title: 'Shipping Policy',
		html: shippingHtml(countryCode),
		showConfirmButton: false,
		showCancelButton: false,
	}
}
export const shortReturnPolicy = () => {
	return {
		...defaultSwalOptions,
		title: 'Return Policy',
		html: `
      <p class="text-center">
        If you're not completely satisfied with your purchase, return your item(s) within 30 days of receipt for a refund.
      </p>
      <p class="note-text text-center">
        *Items that are purchased as part of a collection must be returned as a complete set.
      </p>
      <p class="note-text text-center">
        *Discounted or discontinued items are not eligible for returns or exchanges.
      </p>`,
		showConfirmButton: false,
		showCancelButton: false,
		customClass: {
			content: 'swal2-content--left',
			popup: 'swal2-popup-overrides',
			title: 'swal2-title-overrides',
		},
	}
}
export const sizeGuide = (isBottoms) => {
	const chestSizing = {
		XS: '28 - 32"',
		S: '32 - 36"',
		M: '36 - 38"',
		L: '38 - 41"',
		XL: '41 - 44"',
		'2XL': '44 - 48"',
		'3XL': '48 - 51"',
	}
	const waistSizing = {
		XS: '23 - 26"',
		S: '26 - 29"',
		M: '29 - 32"',
		L: '32 - 35"',
		XL: '35 - 39"',
		'2XL': '39 - 43"',
		'3XL': '43 - 46"',
	}
	const hipsSizing = {
		XS: '33 - 35"',
		S: '35 - 38"',
		M: '38 - 41"',
		L: '41 - 44"',
		XL: '44 - 47"',
		'2XL': '47 - 50"',
	}
	let sizingMarkup = `<span class="header-cell">Size</span>`
	let measureMarkup = ''
	const measureWaistMarkup = `<p class="note-text"><span style="font-weight:600">Waist:</span> Bend to one side and find the crease. Measure fully around the smallest part of your waist.</p>`
	if (isBottoms) {
		sizingMarkup += `<span class="header-cell">Waist</span>
    <span class="header-cell">Hips</span>`
		for (const [size, _] of Object.entries(hipsSizing)) {
			sizingMarkup += `<span class="header-cell">${size}</span>
    <span>${waistSizing[size]}</span>
    <span>${hipsSizing[size]}</span>`
		}
		measureMarkup = `${measureWaistMarkup}<p class="note-text"><span style="font-weight:600">Hips:</span> Stand naturally with your heels together. Measure around the fullest part of your hips across both hip bones.</p>`
	} else {
		sizingMarkup += `<span class="header-cell">Chest</span>
    <span class="header-cell">Waist</span>`
		for (const [size, _] of Object.entries(chestSizing)) {
			sizingMarkup += `<span class="header-cell">${size}</span>
      <span>${chestSizing[size]}</span>
      <span>${waistSizing[size]}</span>`
		}
		measureMarkup = `<p class="note-text"><span style="font-weight:600">Chest:</span> Stand naturally. Measure under your arms, around the widest part of your back and around the fullest part of your bust.</p>${measureWaistMarkup}`
	}
	return {
		...defaultSwalOptions,
		title: 'Size Guide',
		html: `
      <div class="sizing-chart-grid">
        ${sizingMarkup}
      </div>
      <h3 class="text-title">How To Measure</h3>
      ${measureMarkup}`,
		showConfirmButton: false,
		showCancelButton: false,
		customClass: {
			content: 'swal2-content--left',
			popup: 'swal2-popup-overrides',
			title: 'swal2-title-overrides',
		},
	}
}
const convertCurrency = (amt) => Math.round(amt)
const doublePaletteCreditMarkup = (levels, currencySymbol) => {
	let liMarkup = ''
	for (const level of levels) {
		liMarkup += `
      <li>
        <p class="qualifying-amount">
          Spend ${currencySymbol}${convertCurrency(level.qualifying_amount)}+<br />
          <span>on qualifying tins</span>
        </p>
        <div class="credit-amount">
          <sup class="original-sup">${currencySymbol}</sup>
          <h4 class="original-amount">${level.credit / 2}</h4>
          <p class="credit">
            <span class="currency-symbol">${currencySymbol}</span>
            ${level.credit}<br />
            <span class="credit">Credit</span>
          </p>
        </div>
      </li>
    `
	}
	return liMarkup
}
const paletteCreditMarkup = (levels, currencySymbol) => {
	let liMarkup = ''
	for (const level of levels) {
		liMarkup += `
      <li>
        <p class="qualifying-amount">
          Spend ${currencySymbol}${convertCurrency(level.qualifying_amount)}+<br />
          <span>on qualifying tins</span>
        </p>
        <div class="credit-amount">
          <p class="credit">
            <span class="currency-symbol">${currencySymbol}</span>
            ${level.credit}<br />
            <span class="credit">Credit</span>
          </p>
        </div>
      </li>
    `
	}
	return liMarkup
}
const paletteHtml = (liMarkup, description, buttonText) => `
  <div class="palette-credit--v2-wrapper">
    <p class="palette-credit--v2-description">
      ${description}
    </p>
    <div class="palette-credit--v2">
      <div class="top-title">
        <h3>${buttonText}</h3>
      </div>
      <ul>${liMarkup}</ul>
    </div>
    <ol class="palette-credit-terms">
      <li>
        Palette Credit is only applied to one palette per order. Palettes purchased with Palette Credit are not eligible for exchange or return.
      </li>
      <li>
        Qualifying tins include all non-discounted palette tins.
      </li>
    </ol>
  </div>
  `
const prepPaletteCreditHtml = ({ levels, currencySymbol, isDouble = false, description, buttonText }) => {
	const liMarkup = isDouble
		? doublePaletteCreditMarkup(levels, currencySymbol)
		: paletteCreditMarkup(levels, currencySymbol)
	return { html: paletteHtml(liMarkup, description, buttonText) }
}
const shippingHtml = (countryCode) => `
  <p>
    Free shipping on all orders over ${countryCode === 'CA' ? 'C$90' : '$50'}!
  </p>
  <p class="note-text">
    *Customer pays shipping for returns and exchanges.
  </p>
  <p class="note-text">
    *Artist Kits do not qualify for free shipping.
  </p>
`
const prepShippingHtml = () => {
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	return { html: shippingHtml(countryCode) }
}
const shippingAltHtml = () => `
  <p>
    Valid one day only for all orders: 12:00am - 11:59pm MST on September 10th
  </p>
  <p></p>
  <p class="note-text">
    *Customer is responsible for return shipping costs<br />
    *Artist kits do not qualify for free shipping
  </p>
`
const prepShippingAltHtml = () => {
	return { html: shippingAltHtml(), modalTitle: 'Free Shipping Day!' }
}
const abpGiftHtml = (countryCode) => `
  <p>
    Receive a *free <strong>Travel Perfector</strong> or <strong>Perfume - Seint 6</strong> with qualifying order of ${
			countryCode === 'CA' ? 'C$105' : '$75'
		} or more!
  </p>
  <div class="swal-gift-options">
    <div>
      <img src="https://s3.us-east-2.amazonaws.com/seint.com/media/images/promos/travel-perfector.png" alt="Travel Perfector" />
      <p class="gift-description">
        Includes a St. monogrammed, travel friendly zipper case
      </p>
    </div>
    <div>
      <img src="https://s3.us-east-2.amazonaws.com/seint.com/media/images/promos/perfume-seint-6.png" alt="Perfume - Seint 6" />
      <p class="gift-description">
        Infused with white florals, amber woods, exotic musk, and vanilla
      </p>
    </div>
  </div>
  <p class="note-text">
    *Only valid on orders placed on Jan 17, 2023 10:00AM, MST through 11:59PM MST, while supplies last. Orders cannot be combined once they are placed. Free gifts are not available for exchange or store credit. If a free gift is not returned with a returned order, the cost of the gift will be subtracted from the refund. Artist Kits do NOT count toward promotion.
  </p>
`
const prepABPGiftModal = () => {
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	return { html: abpGiftHtml(countryCode), modalTitle: 'Artist Birthday Party Gift!' }
}
const blancPromoHtml = () => `
  <p>
    Artists who sign up from <sup>1</sup>April 22nd - May 12th will receive a free <strong>Blanc Pro Palette 40</strong> and a <sup>2</sup><strong>Blanc Basic Ticket</strong> with their enrollment order.
  </p>
  <p>
    <img src="https://s3.us-east-2.amazonaws.com/seint.com/media/images/promos/pro-palette-modal-image.jpg" alt="Blanc Pro 40 Palette" />
  </p>
  <p class="note-text">
    1. Offer valid on orders of Basic or Pro Artist Kits placed on April 22nd, 10:00 AM MST thru May 12th, 11:59 PM MST, while supplies last. Gifts are not available for exchange or return. If a gift is not returned with a returned order, the cost of the gift will be subtracted from the refund.
  </p>
  <p class="note-text">
    2. Artists will be automatically enrolled with a Blanc Basic Ticket for the Artist virtual event held on May 14, 2022.
  </p>
`
const prepBlancPromoHtml = () => {
	return { html: blancPromoHtml(), modalTitle: `New Artist Promotion` }
}
const brushBlitzHtml = (content, bgImage) => `
  <div class="modal-left">
    <h2 class="modal-title">${content.title}</h2>
    <p>
      ${content.paragraph}
    </p>
    <p class="note-text">
      ${content.note}
    </p>
  </div>
  <div class="modal-right" style="background-image: url(${bgImage})"></div>
`
const brushBlitzBg = () => {
	return `https://s3.us-east-2.amazonaws.com/seint.com/media/images/promos/brush-blitz/6-9-pre.jpg`
}
const brushBlitzContent = () => {
	return {
		title: `Dreaming of flawless makeup application?`,
		paragraph: `Starting on June 12th, we will be offering an exclusive Brush Blitz collection each day that will only be available for 24 hours. After that, the next collection will become available until all five collections have made their debut.`,
		note: `Don’t miss out on the opportunity to take your Seint stash to dreamy new heights!`,
	}
}
const prepBrushBlitzHtml = () => {
	const bgImage = brushBlitzBg()
	const content = brushBlitzContent()
	return { html: brushBlitzHtml(content, bgImage) }
}

const capHtml = ({ liMarkup }) => `
  <div class="cap-2023-wrapper">
    <div class="cap-2023-header">
      <p>
        Once a year we dedicate a day to celebrate our amazing customers! Today is that day! Enjoy savings on your order as a way of us saying thank you for being a part of our community. Happy shopping!
      </p>
    </div>
    <div class="cap-2023-body">
      <div class="cap-2023-level-title">
        <h3>Rewards</h3>
      </div>
      <ul>${liMarkup}</ul>
    </div>
    <p class="note-text">
      *Only valid on orders placed from 12:00AM MDT July 7, 2023 to 11:59PM MDT July 8, 2023. Orders cannot be combined once they are placed. Artist Kits do NOT count toward promotion.
    </p>
  </div>
`
const capLevelMarkup = ({ levels, currencySymbol }) => {
	let liMarkup = ''
	for (const level of levels) {
		liMarkup += `
      <li>
        <p class="qualifying-amount">
          Spend ${currencySymbol}${convertCurrency(level.qualifying_amount)}+
        </p>
        <div class="credit-amount">
          <p class="credit">${level.credit}% Off<br />
            <span>Your entire order</span>
          </p>
        </div>
      </li>
    `
	}
	return liMarkup
}
const prepCapHtml = ({ levels, currencySymbol, title }) => {
	const liMarkup = capLevelMarkup({ levels, currencySymbol })
	return { html: capHtml({ liMarkup }), modalTitle: title }
}

const eyeShadowHtml = ({ modalTitle, webCategoryUrl }) => `
  <div class="modal-left">
    <h2 class="modal-title">
      ${modalTitle}
    </h2>
    <p>
        Receive the Eyeshadow Brush when you purchase your choice of four signature, ultra-pigmented Seint Eyeshadows.
        September 25th at 12am, MDT, thru September 29th at 11:59pm, MDT, or while supplies last
    </p>
    <p class="note-text pb-3">
      *Eyeshadows purchased during September 25-29 will not be eligible for additional Palette Credit
      <br />
      *Promotion is not valid in hostess orders
      <br />
      *Eyeshadow Brush is not eligible for returns/exchanges
      <br />
      *One free Eyeshadow Brush per order
      <br />
      *While supplies last
    </p>
    <a href="${webCategoryUrl}" class="btn btn-primary modal-confirm" aria-label="Eyeshadows">Shop Eyeshadows</a>
    </div>
  <div class="modal-right"></div>
`
const prepEyeShadowHtml = () => {
	const modalTitle = 'Get a free Eyeshadow Brush!'
	const sessionStore = useSessionStore()
	const webCategoryUrl = sessionStore.shopRoute('category.index', {
		language: 'en',
		webCategory: 116,
		slug: 'eyeshadow',
	})
	return {
		...defaultSwalOptions,
		icon: null,
		title: false,
		html: eyeShadowHtml({ modalTitle, webCategoryUrl }),
	}
}
const fallPromoHtml = ({ title, countryCode, url }) => `
  <div class="modal-left">
    <h2 class="modal-title">
      ${title}
    </h2>
    <p>
      Receive a FREE Corduroy Tote Bag with any purchase over ${
				countryCode === 'CA' ? '$189 CAD ($135 USD)' : '$135 USD ($189 CAD)'
			}
    </p>
    <p class="note-text pb-3">
      *Only available while supplies last<br />
      *Free Tote available November 2 at midnight MDT<br />
      *Promotion not available in hostess orders<br />
      *One free Tote per qualifying purchase only
    </p>
    <a href="${url}" class="btn btn-primary modal-confirm" aria-label="Eyeshadows">Shop New Releases</a>
  </div>
  <div class="modal-right"></div>
`
const prepFallPromoHtml = () => {
	const title = 'Get a free corduroy tote!'
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	const url = sessionStore.shopRoute('new-releases', { language: 'en' })
	return { modalTitle: title, html: fallPromoHtml({ title, countryCode, url }) }
}
const blackFridayHtml = ({ url }) => `
  <div class="modal-left">
    <p>
      Select items are 15-50% off Friday, November 24, 12:00am MST through Monday, November 27, 11:59pm MST.
    </p>
    <p class="note-text pb-3">
      *Black Friday Sale does not apply to hostess orders.<br />
      *Sale items are not eligible for returns or exchanges.<br />
      *All sale items are final.
    </p>
    <a href="${url}" class="btn btn-primary modal-confirm" aria-label="Shop Black Friday">Shop Black Friday</a>
  </div>
  <div class="modal-right"></div>
`
const prepBlackFridayHtml = () => {
	const title = 'Shop Black Friday'
	const sessionStore = useSessionStore()
	const webCategoryUrl = sessionStore.shopRoute('category.index', {
		language: 'en',
		webCategory: 391,
		slug: 'black-friday-sale',
	})
	return { modalTitle: title, html: blackFridayHtml({ url }) }
}

const boxingHtml = ({ url }) => `
  <div class="modal-left">
    <p>
      Select items are 40-50% off Tuesday, December 26th 12:00am MST through Sunday, December 31st 11:59pm MST.
    </p>
    <p class="note-text pb-3">
      *Boxing Day Sale does not apply to hostess orders.<br />
      *Sale items are not eligible for returns or exchanges.<br />
      *Discounted palettes cannot be purchased with palette credit.<br />
      *All sale items are final.
    </p>
    <a href="${url}" class="btn btn-primary modal-confirm" aria-label="Shop Boxing Day">Shop Boxing Day</a>
  </div>
  <div class="modal-right"></div>
`
const prepBoxingHtml = () => {
	const title = 'Shop Boxing Day'
	const sessionStore = useSessionStore()
	const url = sessionStore.shopRoute('category.index', {
		language: 'en',
		webCategory: 391,
		slug: 'boxing-day',
	})
	return { modalTitle: title, html: boxingHtml({ url }) }
}
const abpHtml = ({ title, countryCode, url, buttonText }) => `
  <div class="modal-left">
    <h2 class="modal-title">
      ${title}
    </h2>
    <p class="note-text pb-3">
      Receive a FREE Jinx Drawstring Bag with purchases of ${countryCode === 'CA' ? 'C$175' : '$125'} or more!
    </p>
    <p class="note-text modal-special-note">
      *Valid on orders starting January 17, 2024, 12:00 am, MST, while supplies last. Orders cannot be combined once they are placed. Free gifts are not available for exchange or store credit. If a free gift is not returned with a returned order, the cost of the gift will be subtracted from the refund. Artist Kits do NOT count toward promotion.
    </p>
    <a href="${url}" class="btn btn-primary modal-confirm" aria-label="${buttonText}">${buttonText}</a>
  </div>
  <div class="modal-right"></div>
`
const prepABPHtml = ({ title, buttonText }) => {
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	const url = sessionStore.shopRoute('new-releases', {
		language: 'en',
	})
	return { modalTitle: title, html: abpHtml({ title, countryCode, url, buttonText }) }
}
const goldenDayHtml = ({ countryCode }) => `
  <div class="modal-left">
    <h2 class="modal-title">Golden Day Gift!</h2>
    <p>
      Receive a golden surprise with purchases of ${countryCode === 'CA' ? 'C$140' : '$100'} or more!
    </p>
    <p class="note-text">
      *Valid on orders March 17, 2024, 12:00am - 11:59pm, MST, while supplies last. Orders cannot be combined once they are placed. Free gifts are not available for exchange or store credit. If a free gift is not returned with a returned order, the cost of the gift will be subtracted from the refund. Free gift not available in hostess orders. Artist Kits do NOT count toward promotion.
    </p>
  </div>
  <div class="modal-right"></div>
`
const prepGoldDayHtml = () => {
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	return { html: goldenDayHtml({ countryCode }) }
}

const mothersDayHtml = ({ countryCode }) => `
	<div class="modal-left">
		<h2 class="modal-title">Gift Bag with Purchase!</h2>
		<p>
			Receive a *Free Once Upon a Time Gift Bag with purchases of ${countryCode === 'CA' ? 'C$210' : '$150'} or more!
		</p>
		<p class="note-text">
			*Valid on orders starting April 15, 2024, 12:00am, MST, while supplies last. Orders cannot be combined once they are placed. Free gifts are not available for exchange or store credit. If a free gift is not returned with a returned order, the cost of the gift will be subtracted from the refund. Artist Kits do NOT count toward promotion.
		</p>
	</div>
	<div class="modal-right"></div>
`
const prepMothersDayHtml = () => {
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	return { html: mothersDayHtml({ countryCode }), modalTitle: `Gift Bag with Purchase!` }
}
const fosterAwarenessHtml = (foundationsLink) => `
  <div class="modal-left">
    <h2 class="modal-title">Ocean Breeze Foundation Collection</h2>
    <p>
		In honor of Foster Care Awareness Month, all proceeds from the Ocean Breeze Foundation Collection will be donated to Seint Foundations, Love is Never Wasted & Angels Landing, where we are dedicated to helping foster families, children in foster care, and those who have aged out of foster care live the best lives possible.
    </p>
	<p>
		Learn more about our non-profits <a href="${foundationsLink}" aria-label="Learn more about our non-profits">here</a>.
	</p>
	<p>
		*Valid on orders starting May 1, 2024, 12:00am, MST, while supplies last.
	</p>
  </div>
  <div class="modal-right"></div>
`
const prepFosterAwarenessHtml = () => {
	const foundationsLink = route('foundations')
	return { html: fosterAwarenessHtml(foundationsLink) }
}
const abp2024Html = ({ title, countryCode, url }) => `
  <div class="modal-left">
    <h2 class="modal-title">
      ${title}
    </h2>
    <p class="note-text">
		It’s all about you! Your loyalty and love for our products inspire us every day so, for being the heart and soul of Seint, we’re thrilled to offer a special discount as a heartfelt thank you.
    </p>
	<ul class="modal-list">
		<li>Spend ${countryCode === 'CA' ? 'C$210 CAD' : '$150 USD'}, save 10% on your order</li>
		<li>Spend ${countryCode === 'CA' ? 'C$280 CAD' : '$200 USD'}, save 15% on your order</li>
		<li>Spend ${countryCode === 'CA' ? 'C$350 CAD' : '$250 USD'}, save 20% on your order</li>
	</ul>
    <p class="note-text !mt-0">
      *Please note: all limited edition items are only available while supplies last. New items do not apply to hostess orders. Artist kits do not count toward promotion.
    </p>
    <a href="${url}" class="btn btn-primary modal-confirm" aria-label="Shop New Releases">Shop New Releases</a>
  </div>
  <div class="modal-right"></div>
`
const prepCap2024Html = ({ title }) => {
	const sessionStore = useSessionStore()
	const countryCode = sessionStore.countryCode
	const url = sessionStore.shopRoute('new-releases', {
		language: 'en',
	})
	return { modalTitle: title, html: abp2024Html({ title, countryCode, url }) }
}
const atlantaHtml = ({ title, url }) => `
  <div class="modal-left">
    <h2 class="modal-title">
      ${title}
    </h2>
    <p class="note-text">
		Everything you need to create radiant looks!
	</p>
	<p class="note-text">
		/ Ethereal Gradient Square Palette<br/>
		/ Fig Lip + Cheek<br />
		/ Angel Illuminator<br />
	</p>
	<p class="note-text">
		Best of all, each purchase supports the Atlanta Angels in their mission to walk alongside children, youth, and families in the foster care community. Make a difference and grab these limited-edition items while supplies last! Learn more about Atlanta Angels <a href="https://www.atlantaangels.org" target="blank" class="underline">here</a>.
	</p>
    <p class="note-text !mt-3">
      *Valid on orders starting August 19, 2024, 12:00am MST, through September 8, 2024, 12:59pm MST while supplies last.
    </p>
    <a href="${url}" class="btn btn-primary modal-confirm" aria-label="Shop Atlanta Angels's Collection">Shop Atlanta Angels's Collection</a>
  </div>
  <div class="modal-right"></div>
`
const prepAtlantaHtml = ({ title }) => {
	const sessionStore = useSessionStore()
	const url = sessionStore.shopRoute('category.item.show', {
		language: 'en',
		webCategory: 118,
		categoryItem: 1800,
		itemSlug: 'atlanta-angels-foster-care-collection',
		slug: 'atlanta-angels-foster-care-collection',
	})
	return { modalTitle: title, html: atlantaHtml({ title, url }) }
}
const haircareHtml = ({ title, url }) => `
	<div class="modal-left haircare">
		<h2 class="modal-title">
			${title}
		</h2>
		<p class="note-text !text-black">
			Experience luxurious products that soften, hydrate, and restore your locks, plus our top-tier tools to refine and refresh.
			<br />
			<br />
			Your best hair days are just a step away...
		</p>
		<a href="${url}" class="btn btn-primary modal-confirm" aria-label="Shop Haircare Link">Shop Haircare</a>
	</div>
	<div class="modal-right haircare"></div>
`
const prepHaircareHtml = ({ title }) => {
	const sessionStore = useSessionStore()
	const url = sessionStore.shopRoute('category.index', {
		language: 'en',
		webCategory: 539,
		slug: 'haircare',
	})
	return { modalTitle: title, html: haircareHtml({ title, url }) }
}

const generateModalValues = {
	palette_credit: prepPaletteCreditHtml,
	double_palette_credit: prepPaletteCreditHtml,
	free_shipping: prepShippingHtml,
	free_shipping_alt: prepShippingAltHtml,
	blanc_registration: prepBlancPromoHtml,
	abp_2023: prepABPGiftModal,
	cap_2023: prepCapHtml,
	brush_blitz_6_16_2023: prepBrushBlitzHtml, // new version
	eyeshadow_school: prepEyeShadowHtml, // new version
	fall_promo: prepFallPromoHtml, // new version
	bf_2023_15: prepBlackFridayHtml, // new version
	boxing_40: prepBoxingHtml, // new version
	abp_2024: prepABPHtml, // new version
	golden_day: prepGoldDayHtml, // new version
	mothers_day: prepMothersDayHtml, // new version
	foster_awareness: prepFosterAwarenessHtml, // new version
	cap_2024: prepCap2024Html, // new version
	atlanta: prepAtlantaHtml, // new version
	haircare: prepHaircareHtml, // new version
}

export const prepRewardSwal = ({
	levels,
	title,
	id,
	currencySymbol,
	languageCode,
	shoppingContext,
	description,
	buttonText,
}) => {
	// TODO: refactor all swals to blade modal ...someday ...soon
	const generator = generateModalValues[id]
	if (!generator) return
	const { html, modalTitle } = generator({
		levels,
		currencySymbol,
		title,
		languageCode,
		shoppingContext,
		isDouble: id === 'double_palette_credit',
		description,
		buttonText,
	})
	const titleEdit = levels?.[0]?.level_title ? `Earn ${levels[0].level_title}` : `Earn ${title}`
	if (id !== 'atlanta' && id !== 'haircare') {
		// old version of modal
		return {
			...defaultSwalOptions,
			icon: null,
			title: modalTitle || titleEdit,
			html,
			showConfirmButton: false,
			customClass: {
				container: `reward-modal without-actions ${id}`,
				popup: 'swal2-popup-overrides',
				title: 'swal2-title-overrides',
			},
		}
	}
	// new version
	return {
		...defaultSwalOptions,
		icon: null,
		title: false,
		html,
		showConfirmButton: false,
		confirmButtonText: 'Shop Ocean Breeze Collection',
		confirmButtonAriaLabel: 'Shop Ocean Breeze Collection',
		showCancelButton: true,
		cancelButtonText: 'Dismiss',
		cancelButtonAriaLabel: 'Dismiss',
		showClass: {
			popup: 'modal-animated modal-slideInUp',
		},
		hideClass: {
			popup: 'modal-animated modal-slideOutDown',
		},
		customClass: {
			container: 'reward-modal without-actions reward-modal--no-padding',
			popup: 'popup-split-view',
			actions: 'modal-actions',
			confirmButton: 'btn btn-primary modal-confirm',
			cancelButton: 'cursor-pointer text-underline inline-block modal-cancel',
		},
	}
}

export const prepWarningSwal = () => {
	const html = `
    <p>
      Applying a discount code may affect your existing discounts.
    </p>`
	return {
		...defaultSwalOptions,
		icon: null,
		title: `Applying Coupon`,
		html,
		showConfirmButton: false,
		customClass: {
			container: 'reward-modal without-actions',
			popup: 'swal2-popup-overrides',
			title: 'swal2-title-overrides',
		},
	}
}

const HEX_BRIGHTNESS_THRESHOLD = 245
export const isBright = (color) => {
	const hex = color.replace('#', '')
	const c_r = parseInt(hex.substr(0, 2), 16)
	const c_g = parseInt(hex.substr(2, 2), 16)
	const c_b = parseInt(hex.substr(4, 2), 16)
	const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
	return brightness > HEX_BRIGHTNESS_THRESHOLD
}

export const calcLevelProgress = ({ levels, currentAmount }) => {
	const numLevels = levels.length
	const percentPerLevel = 100 / numLevels
	const sumArr = new Array(numLevels).fill(0)
	for (const [idx, level] of levels.entries()) {
		const { qualifies, qualifying_amount } = level
		if (qualifies) {
			// fill segment
			sumArr[idx] = percentPerLevel
		} else {
			let lastQualifiedAmt
			if (idx === 0) {
				lastQualifiedAmt = 0
			} else {
				lastQualifiedAmt = levels[idx - 1].qualifying_amount
			}
			const levelGoal = qualifying_amount - lastQualifiedAmt
			const levelProgress = currentAmount - lastQualifiedAmt
			const levelPercent = levelProgress / levelGoal
			const combinedPercent = percentPerLevel * levelPercent
			sumArr[idx] = parseFloat(combinedPercent.toFixed(2))
			// no need to proceed
			break
		}
	}
	const mySum = (total, num) => total + num
	const computedProgress = sumArr.reduce(mySum)
	return `${computedProgress}%`
}
